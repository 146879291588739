function initDashboardNavbar() {
  if($("#sidebar").length)
  {
    const dashboardBtn = document.querySelector("#dashboard-button");
    const sidebar = document.querySelector("#sidebar");

    dashboardBtn.addEventListener("click", () => {
      sidebar.classList.toggle("active");
      document.querySelector("#dark-filter").style.display = "block";
    });
  };
}

export { initDashboardNavbar };
