function initTerms() {
  document.querySelectorAll(".terms-select").forEach(terms_button => {
    terms_button.addEventListener("click", e =>{
      document.querySelectorAll(".terms-selector-underline").forEach(item =>{
        item.classList.remove("active");
      })
      document.querySelectorAll(".terms-selector-text").forEach(item =>{
        item.classList.remove("active");
      })
      e.currentTarget.querySelector(".terms-selector-underline").classList.add("active");
      e.currentTarget.querySelector(".terms-selector-text").classList.add("active");


      document.querySelectorAll(".tab-pane").forEach(button => {
          button.classList.remove("active");
      })
      
      const pane = document.querySelector("#"+e.currentTarget.href.split("#")[1]);

      pane.classList.add("active");
    })
  })


  $(document).ready(function(){
    function goToByScroll(id) {
      // Scroll
      $('html,body').animate({
          scrollTop: $("#" + id).offset().top - document.querySelector(".navbar-preface-wrapper").offsetHeight
      }, 500);
    }

    //got to tab by url
    if(window.location.hash != "") {
      if(window.location.hash == "#terms" || window.location.hash == "#privacy")
      {
        $('a[href="' + window.location.hash + '"]').click()

        document.querySelectorAll(".terms-selector-underline").forEach(item =>{
          item.classList.remove("active");
        })
        document.querySelectorAll(".terms-selector-text").forEach(item =>{
          item.classList.remove("active");
        })

        let hash = window.location.hash;
        hash = hash.substring(1,hash.length);

        document.querySelector("#terms-selector-underline-"+hash).classList.add("active");
        document.querySelector("#terms-selector-text-"+hash).classList.add("active");

        goToByScroll("terms-wrapper");
      }
    }
  });
}

export {initTerms};
