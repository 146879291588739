import flatpickr from 'flatpickr';

function initDatePicker() {
  flatpickr(".kidBirthdatePicker", {
    altInput: true,
    minDate: new Date().fp_incr(-18 * 365), // max 18yo
    maxDate: new Date().fp_incr(-2 * 365), // min 2yo

  })

  flatpickr(".birthdatePicker", {
    altInput: true,
  })

  flatpickr(".scheduleDatePicker", {
    minDate: new Date().fp_incr(3),
    maxDate: new Date().fp_incr(90), // 90 days from now
  })

  flatpickr(".coffeeDatePicker", {
    minDate: new Date().fp_incr(3),// 90 days from now
  })

  flatpickr(".scheduleTimePicker", {
    altInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    minTime: "10:00",
    maxTime: "19:00",
  })

  flatpickr(".scheduleDatePickerAdmin", {
  })
  flatpickr(".scheduleTimePickerAdmin", {
    altInput: true,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
  })
}

function createDatePicker(selector, minDate = null) {
  return flatpickr(selector, {
    minDate: minDate || null
  });
}

export { initDatePicker, createDatePicker };
