function initPayment() {
  if($(".payment_page").length)
  {
    function toggleButtons(enable) {
        const btns = document.getElementsByClassName("payment-button-el");
        for (let btn of btns) {
          btn.disabled = !enable;
        }
    }
    // disable stripe button

    toggleButtons(false);

    $("#chkTerms").click(function () {
      toggleButtons($(this).is(":checked"));
    });
  }
}
export {initPayment};
