import 'bootstrap';
import 'bootstrap-select';
import 'jquery-ui-bundle';
import { snakeCase } from "lodash";

import { initDatePicker, createDatePicker } from "../components/datepicker";
import {
  initSelect2,
  createSelect2,
  setSelect2Option,
} from "../components/select2";
import { initDashboardNavbar } from "../components/dashboard_navbar";
import { initMagicScroll } from "../components/magic_scrolls";

import { initTerms } from "../pages/terms";
import { initPayment } from "../pages/payment";

import { initDashboard } from "../pages/dashboard";
import { initDarkFilter } from "../components/dark_filter";

initDatePicker();
initSelect2();
initTerms();
initPayment();

initMagicScroll();

initDashboardNavbar();
initDashboard();
initDarkFilter();

window.$ = $;
window.PotalUtil = {
  createSelect2,
  setSelect2Option,
  createDatePicker,
  string: {
    snakeCase,
  },
};