import ScrollMagic from 'scrollmagic';

function initMagicScroll() {
  if($(".home-course").length)
  {
    let controller = new ScrollMagic.Controller();

    $('.home-course').each(function(){
      let course_text_css_selector = `#${ this.id } .home-course-text`;

      let ourScene = new ScrollMagic.Scene({
        triggerElement: this
      }).setClassToggle(course_text_css_selector, 'fade-in')
      .addTo(controller);
    });
  }
}

export {initMagicScroll};
