function initDarkFilter() {
  if($("#sidebar").length)
  {
    const filter = document.querySelector("#dark-filter");
    const sidebar = document.querySelector("#sidebar");

    filter.addEventListener("click", () => {
      sidebar.classList.toggle("active");
      filter.style.display = "none";
    }); 
  };
}

export { initDarkFilter };